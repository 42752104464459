import { memo, useEffect, useState } from 'react';
import styles from './HighlightArrow.module.scss';
import { Typewriter } from '@components/common/Typewriter';
import Arrow from '@assets/arrow-min.png';
import { motion } from 'framer-motion';

export const HighlightArrow = memo(() => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setVisible(true);
        }, 2000);
        return () => clearTimeout(timeout);
    }, []);

    return (
        <motion.div
            className={styles.container}
            initial={{ opacity: 1 }}
            animate={{
                opacity: 0,
                visibility: 'hidden',
                transition: { duration: 0.3, delay: 8 },
            }}>
            <motion.div
                initial={{ opacity: 0, scale: 0.4 }}
                animate={{ opacity: 1, scale: 1, transition: { delay: 0.3, duration: 1 } }}>
                <div>
                    {visible && (
                        <Typewriter
                            words={['Funktionsdemo öffnen']}
                            loop={1}
                            delaySpeed={1000}
                            // @ts-ignore
                            cursor
                        />
                    )}
                </div>
                <img src={Arrow.src} alt="Arrow right" />
            </motion.div>
        </motion.div>
    );
});
